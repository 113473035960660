// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("modernizr/modernizr.custom.js");

require("@rails/ujs").start()
require('data-confirm-modal')

// //require("turbolinks").start()
// require("@rails/activestorage").start()
// require("channels")
import 'browser_timezone_cookie'
global.toastr = require("toastr")

//--- Bootstrap
import 'bootstrap';
require("jquery");
global.$ = require('jquery')
window.jQuery = $;
window.$ = $;



import appInit from './angle/app.init.js';
document.addEventListener('DOMContentLoaded', appInit);


jQuery(document).ready(function() {

	$('body').on('click','.link-dropdow-menu', function(e){
		$(this).toggleClass('fa-chevron-up');
		e.preventDefault();
	});	

	$('body').on('click','.parent-dropdown-menu', function(e){
		$(this).find( "i" ).toggleClass('fa-chevron-up');
		e.preventDefault();
	});	
});

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)
